.wrapper {
    display: flex;
    flex-direction: column;
    padding: 16px 16px 0;
    min-height: 254px;
    align-items: center;
    font-size: 12px;
}

.loadingMessage {
    color: #37414b;
    padding: 2rem ;
}

.row1 {
    display: flex;
    justify-content: end;
    align-items: center;
    margin-bottom: 44px;
    width: 100%;
}

.header {
    font-size: 14px;
    font-weight: 500;
}

.resetButton {
    display: flex;
    align-items: center;
    background: #e7ecf2;
    padding: 4px 8px;
    font-weight: 500;
}

.resetButton span {
    margin-right: 16px;
}