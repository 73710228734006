html, body, p, h1, h2, h3 {
    padding: 0;
    margin: 0;
}

* {
    box-sizing: border-box;
    font-family: 'Rubik';
}

input, button {
    font-family: 'Rubik' !important;
}

.sa-dropdown {
    max-height: 40px !important;
    font-size: 12px !important;
}

.sa-dropdown-wrapper {
    display: flex;
    flex-direction: column;
}

.sa-radio-container {
    align-items: center !important;
}

label {
    color: #37414b;
    font-size: 12px;
    margin-bottom: 4px !important;
}
.sa-dropdown-options {
    max-height: 116px !important;
}