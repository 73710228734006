.wrapper {
    display: flex;
    flex-direction: column;
    padding: 16px;
    color: #37414b;
    --successColor: #009f84;
    --errorColor: #d23219;

}

.row1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.header {
    font-size: 14px;
    font-weight: 500;
}

.resetButton {
    display: flex;
    align-items: center;
    background: #e7ecf2;
    padding: 4px 8px;
    font-weight: 500;
}

.resetButton span {
    margin-right: 16px;
}

.row2 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 16px;
    margin-bottom: 16px;
}

.row3 {
    display: flex;
    justify-content: space-between;
}
.row4 {
    padding-top: 2rem;
    display: flex;
    justify-content:space-between;
}

.status-details {
    display: flex;
    width: 100%;
    font-size: 12px;
}

.status-details-item {
    margin-right: 1rem;
}

.status-details-title {
    margin-bottom: 4px;
}

.timer {
    display: flex;
    flex-direction: column;
    min-width: 130px;
    margin-right: 0;
}

.timer time {
    font-size: 26px;
}

._button {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    border-radius: 4px;
    padding: 14px 28px;
}

.confirmButton {
    composes: _button;
    background: #458dc9;
}

.saveTokenButton, .sendSMSButton {
    composes: _button;
    margin-right: 8px;
    background: var(--successColor);
}
.sendSMSButton {
    margin-right: 0;
}

.saveTokenError, .sendSMSError, .saveCardExistingToken {
    border: solid #d23219 2px;
}

.phoneNumber {
    padding: 0 1rem 0 0;
    min-width: 190px;
}



.input-error {
    color: var(--errorColor);
    font-size: 14px;
}
.error {
    color: var(--errorColor);
    font-size: 26px;
}

.success {
    color: var(--successColor);
    font-size: 26px;
}

.smsCol {
    display: flex;
    padding-bottom: 2rem;
}

.buttonsCol {
    width:100%;
    display:flex;
    flex-direction: column;
    padding-top: 1rem;
}


.buttonsWrapper {
    display: flex;
    justify-content: space-between;
}

.phone-input-error {
    padding-top: 0.5rem;
    max-width: 190px;
    word-wrap: break-word;
}