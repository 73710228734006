.wrapper {
    display: flex;
    flex-direction: column;
    padding: 16px 16px 0;
}

._row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-auto-flow: row;
    gap: 24px;
    margin-bottom: 24px;
}

.row1 {composes: _row; }


.row2 { composes: _row; }

.cardsDropdown {
    grid-column-start: 2;
    grid-column-end: 4;
}

.row3 { composes: _row; }

.radios {
    display: flex;
    margin-top: 4px;
}

.radiosWrapper {
    grid-column-start: 1;
    grid-column-end: 4;
    display: flex;
    flex-direction: column;
}

.button {
    width: 100% !important;
    background: #458DC9;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    border-radius: 4px;
}