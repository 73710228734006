._wrapper {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 12px;
}

._wrapper:not(:last-child)::after {
    content: "";
    position: absolute;
    background: #E6E6E6;
    right: -28px;
    top: 50%;
    width: 28px;
    height: 2px;
}

.wrapperDefault {
    composes: _wrapper;
    background: #fff;
}

.wrapperInProgress {
    composes: _wrapper;
    background: #fff;
    border: 1px solid #009F84;
}

.wrapperInProgress:not(:last-child)::after {
    right: -29px;
}

.wrapperInProgress .detailsWrapper p {
    color: #009F84;
}

.wrapperSuccess {
    composes: _wrapper;
    background: #009F84;
    color: #fff;
}

.wrapperSuccess:not(:last-child)::after {
    background: #009F84;
}

.wrapperFailure {
    composes: _wrapper;
    background: #D23219;
    color: #fff;
}

.wrapperFailure:not(:last-child)::after {
    background: #D23219;
}


.detailsWrapper {
    display: flex;
    flex-direction: column;
    font-size: 14px;
}

.titleWrapper {
    margin-bottom: 8px;
    display: flex;
}

.resetButton {
    margin-right: 8px;
}

.title {
    font-weight: 500;
}

.checkbox {
    border: solid #E6E6E6 1px;
    height: 24px;
    width: 24px;
    display: flex;
    border-radius: 4px;
    background: #fff;
}

.checkbox img {
    margin: auto;
}